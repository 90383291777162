<template>
  <div>
    <filter-slot
      :filter="[]"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :filter-principal="filterPrincipal"
      @reload="$refs['refInCourseList'].refresh()"
    >
      <template #table>
        <b-table
          ref="refInCourseList"
          small
          :items="search"
          sticky-header="50vh"
          :fields="fields"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
          show-empty
          responsive
          @sort-changed="changeStatusOrder"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(client_name)="data">
            <span v-if="moduleId === 6">
              <router-link
                :class="[textLink]"
                :to="{
                  name: 'credit-experts-dashboard',
                  params: {
                    idClient: data.item.account_id,
                  },
                }"
                target="_blank"
                >{{ data.item.client_name }}</router-link
              >
            </span>
            <span v-if="moduleId === 7">
              <router-link
                :class="[textLink]"
                :to="{
                  name: 'boost-credit-dashboard',
                  params: {
                    idClient: data.item.account_id,
                  },
                }"
                target="_blank"
                >{{ data.item.client_name }}</router-link
              >
            </span>
            <span v-else-if="moduleId === 14">
              <router-link
                :class="[textLink]"
                :to="{
                  name: 'bookeeping-dashboard',
                  params: {
                    idClient: data.item.account_id,
                  },
                }"
                target="_blank"
                >{{ data.item.client_name }}</router-link
              >
            </span>
            <span v-else-if="module === 20">
              <router-link
                :class="[textLink]"
                :to="{
                  name: 'connection-clients-account',
                  params: {
                    idClient: data.item.account_id,
                  },
                }"
                target="_blank"
                >{{ data.item.client_name }}</router-link
              >
            </span>
            <router-link
              target="_blank"
              v-else-if="moduleId === 8"
              class
              :to="`/taxresearch/clients/account/${data.item.account_id}`"
              >{{ data.item.client_name }}</router-link
            >
          </template>

          <template #cell(status)="data">
            <div class="text-center">
              <status-account
                :account="data.item"
                :text="false"
              ></status-account>
            </div>
          </template>

          <template #cell(fee_amount)="data">
            <span>$ {{ data.item.fee_amount }}</span>
          </template>

          <template #cell(created_at)="data">
            <span>{{ data.item.created_at | myGlobal }}</span>
          </template>

          <template #cell(entry_date)="data">
            <span>{{ data.item.entry_date | myGlobal }}</span>
          </template>

          <template #cell(file)="data">
            <div class="d-flex justify-content-center">
              <feather-icon
                v-if="data.item.file_status == null"
                class="cursor-pointer text-secondary"
                icon="CheckCircleIcon"
                size="18"
                @click="
                  checkStatus(
                    data.item,
                    data.item.id,
                    1,
                    1,
                    data.item.file_status,
                    data.item.call_status,
                    data.item.sms_status,
                    data.item.dates_calls
                  )
                "
              />
              <feather-icon
                v-else
                v-b-tooltip.hover.left="data.item.file_status"
                class="cursor-pointer text-success"
                icon="CheckCircleIcon"
                size="18"
                @click="
                  checkStatus(
                    data.item,
                    data.item.id,
                    1,
                    2,
                    data.item.file_status,
                    data.item.call_status,
                    data.item.sms_status,
                    data.item.dates_calls
                  )
                "
              />
            </div>
          </template>

          <template #cell(call)="data">
            <div class="text-center">
              <b-badge
                :class="data.item.call_status > 0 ? 'cursor-pointer' : ''"
                variant="danger"
                @click="
                  data.item.call_status > 0
                    ? checkStatus(
                        data.item,
                        data.item.id,
                        2,
                        1,
                        data.item.file_status,
                        data.item.call_status,
                        data.item.sms_status,
                        data.item.dates_calls
                      )
                    : ''
                "
              >
                <feather-icon icon="MinusIcon" />
              </b-badge>
              <b-badge
                :id="'call-target-' + data.index"
                variant="primary"
                style="width: 40px; margin-left: 0.3rem; margin-right: 0.3rem"
              >
                {{ data.item.call_status }}
              </b-badge>
              <b-tooltip
                v-if="
                  (JSON.parse(data.item.dates_calls)
                    ? JSON.parse(data.item.dates_calls).length
                    : 0) > 0
                "
                :target="'call-target-' + data.index"
                triggers="hover"
                placement="left"
              >
                <div>
                  <span
                    v-for="(item, i) in JSON.parse(data.item.dates_calls)"
                    :key="i"
                  >
                    {{ item }}<br />
                  </span>
                </div>
              </b-tooltip>
              <b-badge
                class="cursor-pointer"
                variant="success"
                @click="
                  checkStatus(
                    data.item,
                    data.item.id,
                    2,
                    2,
                    data.item.file_status,
                    data.item.call_status,
                    data.item.sms_status,
                    data.item.dates_calls
                  )
                "
              >
                <feather-icon icon="PlusIcon" />
              </b-badge>
            </div>
          </template>

          <template #cell(sms)="data">
            <div class="text-center">
              <b-badge
                variant="danger"
                :class="data.item.sms_status > 0 ? 'cursor-pointer' : ''"
                @click="
                  data.item.sms_status > 0
                    ? checkStatus(
                        data.item,
                        data.item.id,
                        3,
                        1,
                        data.item.file_status,
                        data.item.call_status,
                        data.item.sms_status,
                        data.item.dates_sms
                      )
                    : ''
                "
              >
                <feather-icon icon="MinusIcon" />
              </b-badge>
              <b-badge
                :id="'sms-target-' + data.index"
                variant="primary"
                style="width: 40px; margin-left: 0.3rem; margin-right: 0.3rem"
              >
                {{ data.item.sms_status }}
              </b-badge>
              <b-tooltip
                v-if="
                  (JSON.parse(data.item.dates_sms)
                    ? JSON.parse(data.item.dates_sms).length
                    : 0) > 0
                "
                :target="'sms-target-' + data.index"
                triggers="hover"
                placement="left"
              >
                <div>
                  <span
                    v-for="(item, i) in JSON.parse(data.item.dates_sms)"
                    :key="i"
                  >
                    {{ item }}<br />
                  </span>
                </div>
              </b-tooltip>
              <b-badge
                variant="success"
                class="cursor-pointer"
                @click="
                  checkStatus(
                    data.item,
                    data.item.id,
                    3,
                    2,
                    data.item.file_status,
                    data.item.call_status,
                    data.item.sms_status,
                    data.item.dates_sms
                  )
                "
              >
                <feather-icon icon="PlusIcon" />
              </b-badge>
            </div>
          </template>

          <template #cell(finished)="data">
            <div class="d-flex justify-content-center">
              <b-button
                variant="success"
                size="sm"
                :disabled="
                  !(
                    data.item.call_status > 0 &&
                    data.item.file_status != null &&
                    data.item.sms_status > 0
                  )
                "
                @click="finishTracking(data.item.id)"
              >
                Finish
              </b-button>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </div>
</template>

<script>
import fields from "@/views/commons/components/tracking-list/views/data/fields.tracking-list";
import trackingListService from "@/views/commons/components/tracking-list/service/tracking-list.service";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import { mapGetters, mapState } from "vuex";
import moment from "moment";

export default {
  components: {
    StatusAccount,
  },
  data() {
    return {
      fields,
      isBusy: false,
      items: "",
      totalRows: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      nextPage: 0,
      endPage: 0,
      noteStatus: 0,
      order: "asc",
      startPage: null,
      toPage: null,
    };
  },
  async created() {
    this.fields[6].sortable = true;
    this.fields[this.fields.length - 2].sortable = false;
  },
  computed: {
    ...mapState("BoostCreditTrackingList", [
      "S_SELECT_DATE",
      "S_SELECTED_USER",
    ]),
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
    currentDate: {
      get() {
        const date = new Date();
        const currentDate = {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
        };
        return currentDate;
      },
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    async checkStatus(item, id, type, status, item1, item2, item3, datos) {
      try {
        const date = moment().format("MM/DD/YYYY hh:mm A");
        const array = datos == null ? [] : JSON.parse(datos);
        if (status === 2) {
          array.push(date);
        } else {
          array.pop();
        }

        const data = await trackingListService.updateStatusTracking(
          {
            id,
            type,
            status,
            newval: array,
          },
          this.moduleId == 20
        );
        if (data.status === 200) {
          if (type == 1 && (status == 1 || status == 2)) {
            item.file_status = data.data[0].file_status;
          } else if (type == 2 && (status == 1 || status == 2)) {
            item.call_status = data.data[0].call_status;
          } else if (type == 3 && (status == 1 || status == 2)) {
            item.sms_status = data.data[0].sms_status;
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async search(ctx) {
      let orderBy = 10;
      let sortDirection = "asc";
      if (ctx.sortBy === "created_at") {
        orderBy = 10;
      } else if (ctx.sortBy === "entry_date") {
        orderBy = 11;
      }
      if (ctx.sortDesc) sortDirection = "desc";
      else sortDirection = "asc";
      const params = {
        text: this.filterPrincipal.model,
        year: this.S_SELECT_DATE.year,
        user: this.S_SELECTED_USER,
        month: this.S_SELECT_DATE.month.value,
        type: 1,
        page: ctx.currentPage,
        perPage: this.paginate.perPage,
        order: sortDirection,
        orderby: orderBy,
      };
      // <-- For credit experts connection
      if (this.moduleId == 20) {
        params.is_connection = true;
      }
      // --<
      try {
        const data = await trackingListService.searchTrackingList(params);
        if (data.status === 200) {
          this.items = data.data.data;
          this.startPage = data.data.from;
          this.paginate.currentPage = data.data.current_page;
          this.paginate.perPage = data.data.per_page;
          this.nextPage = this.startPage + 1;
          this.endPage = data.data.last_page;
          this.totalRows = data.data.total;
          this.toPage = data.data.to;
          return this.items;
        }
      } catch (e) {
        console.log(e);
      }

      return this.items || [];
    },
    changeStatusOrder() {
      this.order = this.order === "asc" ? "desc" : "asc";
    },
    async finishTracking(id) {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          const data = await trackingListService.finishTrackingList(
            { id },
            this.moduleId == 20
          );
          if (data.status === 200) {
            this.$refs.refInCourseList.refresh();
            this.removePreloader();
            this.showSuccessSwal();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
  },
};
</script>

<style>
</style>
