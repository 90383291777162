export default [
    {
      key: "client_name",
      label: "Name",
    },
    {
      key: "mobile",
      label: "Mobile",
    },
    {
      key: "account",
      label: "Account",
    },
    {
      key: "status",
      label: "Status",
    },
    {
      key: "fee_amount",
      label: "Fee",
    },
    {
      key: "advisor_name",
      label: "Advisor",
    },
    {
      key: "created_at",
      label: "Enrol Date",
      sortable: true,
    },
    {
        key: "entry_date",
        label: "Entry Date",
        sortable: true,
    },
    {
        key: "file",
        label: "file",
        thStyle: {
            textAlign: "center",
        }
    },
    {
        key: "call",
        label: "Call",
        thStyle: {
            textAlign: "center",
        }
    },
    {
        key: "sms",
        label: "SMS",
        thStyle: {
            textAlign: "center",
        }
    },
    {
        key: "finished",
        label: "Finished",
        thStyle: {
            textAlign: "center",
        },
        sortable: false,
    },
    {
      key: "actions",
      label: "",
    }
]