var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-slot',{attrs:{"filter":[],"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage,"filter-principal":_vm.filterPrincipal},on:{"reload":function($event){return _vm.$refs['refInCourseList'].refresh()}},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"refInCourseList",attrs:{"small":"","items":_vm.search,"sticky-header":"50vh","fields":_vm.fields,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage,"busy":_vm.isBusy,"show-empty":"","responsive":""},on:{"update:busy":function($event){_vm.isBusy=$event},"sort-changed":_vm.changeStatusOrder},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(client_name)",fn:function(data){return [(_vm.moduleId === 6)?_c('span',[_c('router-link',{class:[_vm.textLink],attrs:{"to":{
                name: 'credit-experts-dashboard',
                params: {
                  idClient: data.item.account_id,
                },
              },"target":"_blank"}},[_vm._v(_vm._s(data.item.client_name))])],1):_vm._e(),(_vm.moduleId === 7)?_c('span',[_c('router-link',{class:[_vm.textLink],attrs:{"to":{
                name: 'boost-credit-dashboard',
                params: {
                  idClient: data.item.account_id,
                },
              },"target":"_blank"}},[_vm._v(_vm._s(data.item.client_name))])],1):(_vm.moduleId === 14)?_c('span',[_c('router-link',{class:[_vm.textLink],attrs:{"to":{
                name: 'bookeeping-dashboard',
                params: {
                  idClient: data.item.account_id,
                },
              },"target":"_blank"}},[_vm._v(_vm._s(data.item.client_name))])],1):(_vm.module === 20)?_c('span',[_c('router-link',{class:[_vm.textLink],attrs:{"to":{
                name: 'connection-clients-account',
                params: {
                  idClient: data.item.account_id,
                },
              },"target":"_blank"}},[_vm._v(_vm._s(data.item.client_name))])],1):(_vm.moduleId === 8)?_c('router-link',{attrs:{"target":"_blank","to":("/taxresearch/clients/account/" + (data.item.account_id))}},[_vm._v(_vm._s(data.item.client_name))]):_vm._e()]}},{key:"cell(status)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_c('status-account',{attrs:{"account":data.item,"text":false}})],1)]}},{key:"cell(fee_amount)",fn:function(data){return [_c('span',[_vm._v("$ "+_vm._s(data.item.fee_amount))])]}},{key:"cell(created_at)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm._f("myGlobal")(data.item.created_at)))])]}},{key:"cell(entry_date)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm._f("myGlobal")(data.item.entry_date)))])]}},{key:"cell(file)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[(data.item.file_status == null)?_c('feather-icon',{staticClass:"cursor-pointer text-secondary",attrs:{"icon":"CheckCircleIcon","size":"18"},on:{"click":function($event){return _vm.checkStatus(
                  data.item,
                  data.item.id,
                  1,
                  1,
                  data.item.file_status,
                  data.item.call_status,
                  data.item.sms_status,
                  data.item.dates_calls
                )}}}):_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(data.item.file_status),expression:"data.item.file_status",modifiers:{"hover":true,"left":true}}],staticClass:"cursor-pointer text-success",attrs:{"icon":"CheckCircleIcon","size":"18"},on:{"click":function($event){return _vm.checkStatus(
                  data.item,
                  data.item.id,
                  1,
                  2,
                  data.item.file_status,
                  data.item.call_status,
                  data.item.sms_status,
                  data.item.dates_calls
                )}}})],1)]}},{key:"cell(call)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_c('b-badge',{class:data.item.call_status > 0 ? 'cursor-pointer' : '',attrs:{"variant":"danger"},on:{"click":function($event){data.item.call_status > 0
                  ? _vm.checkStatus(
                      data.item,
                      data.item.id,
                      2,
                      1,
                      data.item.file_status,
                      data.item.call_status,
                      data.item.sms_status,
                      data.item.dates_calls
                    )
                  : ''}}},[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1),_c('b-badge',{staticStyle:{"width":"40px","margin-left":"0.3rem","margin-right":"0.3rem"},attrs:{"id":'call-target-' + data.index,"variant":"primary"}},[_vm._v(" "+_vm._s(data.item.call_status)+" ")]),(
                (JSON.parse(data.item.dates_calls)
                  ? JSON.parse(data.item.dates_calls).length
                  : 0) > 0
              )?_c('b-tooltip',{attrs:{"target":'call-target-' + data.index,"triggers":"hover","placement":"left"}},[_c('div',_vm._l((JSON.parse(data.item.dates_calls)),function(item,i){return _c('span',{key:i},[_vm._v(" "+_vm._s(item)),_c('br')])}),0)]):_vm._e(),_c('b-badge',{staticClass:"cursor-pointer",attrs:{"variant":"success"},on:{"click":function($event){return _vm.checkStatus(
                  data.item,
                  data.item.id,
                  2,
                  2,
                  data.item.file_status,
                  data.item.call_status,
                  data.item.sms_status,
                  data.item.dates_calls
                )}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)]}},{key:"cell(sms)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_c('b-badge',{class:data.item.sms_status > 0 ? 'cursor-pointer' : '',attrs:{"variant":"danger"},on:{"click":function($event){data.item.sms_status > 0
                  ? _vm.checkStatus(
                      data.item,
                      data.item.id,
                      3,
                      1,
                      data.item.file_status,
                      data.item.call_status,
                      data.item.sms_status,
                      data.item.dates_sms
                    )
                  : ''}}},[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1),_c('b-badge',{staticStyle:{"width":"40px","margin-left":"0.3rem","margin-right":"0.3rem"},attrs:{"id":'sms-target-' + data.index,"variant":"primary"}},[_vm._v(" "+_vm._s(data.item.sms_status)+" ")]),(
                (JSON.parse(data.item.dates_sms)
                  ? JSON.parse(data.item.dates_sms).length
                  : 0) > 0
              )?_c('b-tooltip',{attrs:{"target":'sms-target-' + data.index,"triggers":"hover","placement":"left"}},[_c('div',_vm._l((JSON.parse(data.item.dates_sms)),function(item,i){return _c('span',{key:i},[_vm._v(" "+_vm._s(item)),_c('br')])}),0)]):_vm._e(),_c('b-badge',{staticClass:"cursor-pointer",attrs:{"variant":"success"},on:{"click":function($event){return _vm.checkStatus(
                  data.item,
                  data.item.id,
                  3,
                  2,
                  data.item.file_status,
                  data.item.call_status,
                  data.item.sms_status,
                  data.item.dates_sms
                )}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)]}},{key:"cell(finished)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{attrs:{"variant":"success","size":"sm","disabled":!(
                  data.item.call_status > 0 &&
                  data.item.file_status != null &&
                  data.item.sms_status > 0
                )},on:{"click":function($event){return _vm.finishTracking(data.item.id)}}},[_vm._v(" Finish ")])],1)]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }